<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">
        <app-i18n code="home.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item :to="{ path: '/user' }">
        <app-i18n code="user.menu"></app-i18n>
      </el-breadcrumb-item>
      <el-breadcrumb-item>
        <app-i18n code="user.new.title"></app-i18n>
      </el-breadcrumb-item>
    </el-breadcrumb>

    <div class="app-content-page">
      <h1 class="app-content-title">
        <app-i18n code="user.new.title"></app-i18n>
      </h1>

      <app-user-new-form
        :saveLoading="saveLoading"
        @cancel="doCancel"
        @submit="doSubmit"
      ></app-user-new-form>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import UserNewForm from '@/modules/user/components/user-new-form';
import { routerAsync } from '@/app-module';

export default {
  name: 'app-user-new-page',

  components: {
    [UserNewForm.name]: UserNewForm,
  },

  computed: {
    ...mapGetters({
      saveLoading: 'user/form/saveLoading',
    }),
  },

  async created() {
    await this.doNew();
  },

  methods: {
    ...mapActions({
      doNew: 'user/form/doNew',
      doAdd: 'user/form/doAdd',
    }),

    doCancel() {
      routerAsync().push('/user');
    },

    async doSubmit(payload) {
      return this.doAdd(payload.values);
    },
  },
};
</script>
